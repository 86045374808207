<!-- 商品详情 -->
<template>
  <div class="mui-content" v-if="product">
    <van-swipe :autoplay="bannerTime" @change="onChange" v-if="product.bannersArr">
      <van-swipe-item v-for="(image, index) in product.bannersArr" :key="index">
        <img v-lazy="image" preview="0" preview-text="" />
      </van-swipe-item>
      <div class="custom-indicator" slot="indicator">
        {{ current + 1 }}/{{ product.bannersArr.length }}
      </div>
    </van-swipe>
    <!--商品信息-->
    <div class="product-body">
      <div class="price-box">
        <span class="vip">￥</span><span class="vip number">{{ product.price }}</span>
        <div class="share-btn" @click="handleShare()">
          <span class="icon-share"></span>
        </div>
      </div>
      <div class="pro-name mui-ellipsis">{{ product.courseTitle }}</div>
      <p class="sold-num">已订购{{ product.salesCount }}</p>
    </div>
    <!--商品规格-->
    <div class="pro-content">
      <div class="sub-title">
        <span>商品详情</span>
      </div>
      <div class="pro-detail" v-html="product.introduce"></div>
    </div>
    <div class="footer">
      <!-- <div class="box">
        <span class="icon-030"></span>
        <span class="mui-badge">{{ TotalCount }}</span>
      </div>
      <div class="car-money"> <span>总价</span>￥{{ totalAmount }} </div> -->
      <van-button @click="onPurchase()">立即购买</van-button>
    </div>
    <div class="share-guid" v-if="shareGuidLayer" @click="shareGuidLayer = false">
      <img src="http://freesaasofficedata.oss.aliyuncs.com/rolorweb/20230807135039335.png" />
      <div class="prompt">请点击这里分享</div>
    </div>
  </div>
</template>

<script>
let wx = require('weixin-js-sdk');
import { deliveryMessage } from "../../utils/appUtil";
export default {
  name: '',
  data() {
    return {
      proId: 0,
      product: null,
      TotalCount: 0,
      count: 0,
      initCount: 0,
      brandName: '',
      bannerTime: 5000,
      current: 0,//轮播索引
      productValue: 0,
      totalAmount: 0,
      isApp: "0",
      shareGuidLayer: false //分享引导
    }
  },
  created() {
    let { proId, isapp } = this.$route.query;
    this.isApp = isapp;
    this.proId = +proId;
    this.getProductById();
  },
  methods: {
    //根据商品id获取商品数据
    getProductById: function () {
      this.$toast.loading({ duration: 0, message: '加载中...', overlay: true });
      this.get('/GroupBuy/OnlineCourse/GetCourseDetails', {
        id: this.proId
      }, 2).then(json => {
        this.$toast.clear();
        if (json && json.code === 1) {
          this.product = json.response;
          if (this.isApp !== '1') this.shareToFriend();
        }
      })
    },
    back: function () {
      window.history.go(-1);
    },
    //轮播
    onChange: function (index) {
      this.current = index;
      var list = this.$refs.videoList;
      if (list && list.length > 0) {
        for (var i = 0; i < list.length; i++) {
          list[i].pause();
        }
      }
    },
    //点击立即购买
    onPurchase() {
      this.$router.push("/Recharge/courseConfirm?proId=" + this.proId + "&isapp=" + this.isApp)
    },
    //点击分享
    handleShare() {
      if (!this.product) return;
      if (this.isApp === '1') {
        this.openAppShare();
      } else {
        this.shareGuidLayer = true;
      }
    },
    shareToFriend: function () {
      let that = this;
      let { id, courseTitle, coverImg } = this.product;
      let desc = courseTitle;
      let url = this.baseHost + '/#/online/productDetail?proId=' + id + "&isapp=0";
      this.get('/Base/Api/GetWebToWebParams', {
        url, v: 1
      }, 2).then(json => {
        if (json.code === 1) {
          let { appId, nonceStr, signature, timestamp } = json.response;
          wx.config({
            debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
            appId: appId, // 必填，公众号的唯一标识
            timestamp: timestamp, // 必填，生成签名的时间戳
            nonceStr: nonceStr, // 必填，生成签名的随机串
            signature: signature,// 必填，签名，见附录1
            jsApiList: [
              // 所有要调用的 API 都要加到这个列表中
              'checkJsApi',
              'updateAppMessageShareData',
              'updateTimelineShareData'
            ] // 必填，需要使用的JS接口列表，所有JS接口列表见附录2

          });
          wx.ready(function () {
            wx.updateAppMessageShareData({
              imgUrl: coverImg,
              link: url,
              title: courseTitle,
              desc,
              success: (res) => {
              }
            });
            wx.updateTimelineShareData({
              imgUrl: coverImg,
              link: url,
              title: courseTitle,
              desc,
              success: (res) => {
              }
            });
          });
        }
      })
    },
    //调用app分享
    openAppShare: function () {
      let { id, courseTitle, coverImg } = this.product;
      let url = this.baseHost + '/#/online/productDetail?proId=' + id;
      let desc = courseTitle;
      let message = {
        action: 'jumpNativeShare',
        data: {
          url: url,
          title: courseTitle,
          desc,
          iconUrl: coverImg
        }
      };
      deliveryMessage(message);
    }
  }
}
</script>
<style lang='scss' scoped>
.mui-content {
  text-align: left;
  margin-bottom: 60px;
  background-color: #f8f8f8;

  .van-swipe {
    .van-swipe-item {
      font-size: 0;
    }

    img {
      width: 100%;
      height: 100%;
    }

    .custom-indicator {
      position: absolute;
      color: #fff;
      right: 15px;
      bottom: 15px;
      padding: 2px 15px;
      border-radius: 20px;
      background-color: rgba(0, 0, 0, 0.4);
    }

    .video-box {
      img {
        position: relative;
        z-index: 10;
      }

      video {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        z-index: 5;
      }

      .icon-video {
        position: absolute;
        color: #fff;
        top: calc((100% - 60px)/2);
        left: calc((100% - 60px)/2);
        z-index: 12;
        font-size: 60px;

        &:before {
          content: "\e933";
        }
      }

      &.playing {
        img {
          display: none;
        }

        .icon-video {
          display: none;
        }

        &:after {
          z-index: 0;
          background-color: transparent;
        }
      }

      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 11;
        background-color: rgba(0, 0, 0, 0.3);
      }
    }

    &.banner {
      height: 191px;
    }
  }

  .product-body {
    padding: 0 15px 15px;
    background-color: #fff;

    .price-box {
      padding-top: 20px;
      position: relative;

      .vip {
        color: #ff98a0;
        font-size: 18px;
      }

      .number {
        font-size: 30px;
        font-weight: bold;
      }

      .vip-text {
        color: #fff;
        font-size: 12px;
        padding: 4px 7px;
        line-height: 10px;
        border-radius: 18px;
        display: inline-block;
        background-color: #ffb5bb;
      }

      del {
        color: #b5b5b5;
      }

      .operate {
        float: right;

        a {
          padding: 0 5px;
          text-align: center;
          display: inline-block;

          .mi-icon {
            color: #666;
            font-size: 20px;
          }

          p {
            color: #666;
            font-size: 12px;
          }
        }
      }

      .share-btn {
        position: absolute;
        top: 10px;
        right: -10px;
        padding: 10px;

        .icon-share {
          color: #666;
          font-size: 20px;
        }
      }
    }

    .pro-name {
      color: #666;
      padding: 10px 0;
      font-size: 16px;
      font-weight: bold;
    }

    .delivery-time {
      color: #ff98a0;
    }

    .sold-num {
      color: #b5b5b5;
    }
  }

  .mi-norm {
    margin-top: 10px;
    padding: 11px 15px;
    background-color: #fff;

    .mi-item {
      padding: 15px 0;
      overflow: hidden;
      position: relative;

      label {
        float: left;
        color: #999;
        width: 50px;
      }

      .mi-item-body {
        overflow: hidden;

        p {
          color: #666;
          font-size: 12px;
        }
      }

      &:after {
        content: '';
        clear: both;
        position: absolute;
        left: 50px;
        right: 0;
        bottom: 0;
        height: 1px;
        background-color: #f1f1f1;
      }

      &:first-child {
        padding-top: 0;
      }

      &:last-child {
        padding-bottom: 0;

        &:after {
          height: 0;
        }
      }
    }
  }

  .pro-content {
    padding-top: 10px;

    .sub-title {
      padding: 15px;
      position: relative;
      background-color: #fff;

      span {
        color: #666;
        font-size: 14px;
        background-color: #fff;
      }
    }

    .pro-detail {
      /deep/ img {
        max-width: 100%;
      }
    }
  }

  .footer {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    height: 50px;
    z-index: 999;
    display: flex;
    align-items: center;
    padding: 0 15px;
    background-color: #fff;
    -webkit-box-shadow: 0 0 1px rgba(0, 0, 0, 0.85);
    box-shadow: 0 -1px 6px rgba(0, 0, 0, 0.1);

    .box {
      width: 48px;
      height: 48px;
      margin-top: -10px;
      margin-right: 10px;
      position: relative;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      background-color: #ffb5bb;
      border: 2px solid #fff;

      .icon-030 {
        color: #fff;
        font-size: 22px;
      }

      .mui-badge {
        position: absolute;
        top: -5px;
        right: -8px;
        color: #ff98a0;
        font-size: 12px;
        line-height: 1;
        padding: 1px 6px;
        border-radius: 100px;
        display: inline-block;
        border: 1px solid #ff98a0;
        background-color: #fff;
      }
    }

    .car-money {
      flex: 1;
      color: #FF98A0;
      font-size: 16px;

      span {
        color: #666;
        font-size: 12px;
        display: inline-block;
        vertical-align: text-top;
      }
    }

    .van-button {
      font-size: 15px;
      height: 37px;
      color: #fff;
      padding: 0 20px;
      margin-left: auto;
      border-radius: 37px;
      background-color: #FF98A0;
    }

  }

  .share-guid {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2001;
    text-align: right;
    background-color: rgba($color: #000000, $alpha: 0.5);

    .prompt {
      color: #fff;
      font-size: 18px;
      padding-top: 10px;
      text-align: center;
    }
  }

}


//图标字体
.mui-content {
  .icon-030:before {
    content: "\e92c"
  }

  .icon-share:before {
    content: "\ea11";
  }
}
</style>